import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import GreenHeading from "./heading"
import device, { sizes } from "./device"
import useLinks from "./hooks/useLinks"

import appStore from "../images/footer/app-store-badge.svg"
import googlePlay from "../images/footer/google-play-badge.svg"

const Wrapper = styled.section`
  position: relative;
  z-index: 1;
  margin-top: -5%;
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 1;
  ${device.small`margin-top: 0;`}
  .background {
    position: relative;
    z-index: -2;
    display: block;
    grid-row: 1/-1;
    grid-column: 1/-1;
    width: 100%;
    height: auto;
    ${device.small`min-height: 750px;`}
  }

  .content-wrapper {
    margin: 0 auto;
    width: 80%;
    max-width: 1500px;
    grid-row: 1/-1;
    grid-column: 1/-1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${device.small`align-items: flex-start; width: var(--spread);`}
  }
`

const Content = styled.div`
  width: 50%;
  ${device.small`width: 100%; padding-top: 4rem; text-align: center;`}
  .heading {
    margin: 0 auto;
    font-size: 2.5rem;
    line-height: 0.8;
    ${device.small`font-size: 2rem;`}
    .secondary-heading {
      color: var(--black);
      margin-left: 3rem;
      ${device.medium`margin-left: 2rem;`}
    }
  }
  h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    margin-top: 1rem;
  }
  p {
    font-size: 0.8rem;
    margin: 0.5rem 0 1rem 0;
  }
  .download-links {
    a {
      ${device.small`display: block; margin: 1rem 0;`}
      &:last-child {
        margin-left: 1rem;
        ${device.small`margin-left: 0;`}
      }
      img {
        border: 1px solid black;
        border-radius: 5px;
        width: 120px;
      }
    }
  }
`

const Download = ({ text }) => {
  const extLinks = useLinks()
  const imgData = useStaticQuery(graphql`
    query {
      desktopBg: file(relativePath: { eq: "download/bg-desktop-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      mobileBg: file(relativePath: { eq: "download/bg-mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const sources = [
    imgData.mobileBg.childImageSharp.fluid,
    {
      ...imgData.desktopBg.childImageSharp.fluid,
      media: `(min-width: ${sizes.small}px)`,
    },
  ]

  return (
    <Wrapper>
      <Img fluid={sources} className="background" />
      <div className="content-wrapper">
        <Content>
          <h2 className="heading">
            <GreenHeading text="Download" />
            <br />
            <span className="secondary-heading">Our Mobile App</span>
          </h2>
          <h3>Available for Android & IOS</h3>
          <p>{text}</p>
          <div className="download-links">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={extLinks.app_store}
            >
              <img src={appStore} alt="App Store Download Link" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={extLinks.google_play}
            >
              <img src={googlePlay} alt="Google Play Download Link" />
            </a>
          </div>
        </Content>
      </div>
    </Wrapper>
  )
}

export default Download
